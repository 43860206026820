import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'menu';

class Menu {
    constructor(element, options) {
        this.$el = $(element);
        this.options = options;

        this.$button = this.$el.find('.c-header__button');

        this.$button.on('click', this.openMenu.bind(this));
    }

    openMenu(e) {
        e.preventDefault();

        $('body').toggleClass('is-scroll-blocked');
        $(this.$el).toggleClass('is-open');
        $('.c-menu').toggleClass('is-open');
        // $('.c-header__home').toggleClass('d-none');

        return this;
    }

    destroy() {
        return this;
    }
}

Menu.DEFAULTS = {};

plugin(pluginName, Menu);

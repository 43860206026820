import $ from 'jquery';
import Jumpto from './jquery.jumpto';
import Cardslider from './jquery.cardslider';
import Gallery from './jquery.gallery';
import Cardslidervertical from './jquery.cardslidervertical';
import Swiperpage from './jquery.swiperpage';
import Menuslider from './jquery.menuslider';
import Wall from './jquery.wall';
import Menu from './jquery.menu';
import Reveal from './jquery.reveal';
import Screveal from './jquery.screveal';
import Modal from './jquery.modal';
import Form from './jquery.form';
import Loading from './jquery.loading';

const mapDOMPluginElements = ($context, callback) => {
  const pluginList = [];

  $context.find('[class*="js-"]').each((index, element) => {
    const pluginClass = $(element)
      .attr('class')
      .match(/js-[\w-]*\b/)[0];
    const pluginName = pluginClass.replace('js-', '');
    if ($.fn[pluginName] && !pluginList.includes(pluginName)) {
      pluginList.push(pluginName);
      callback(pluginClass, pluginName);
    }
  });
};

export default {
  init($context) {
    const $ctx = $context || $('html');

    mapDOMPluginElements($ctx, (pluginClass, pluginName) => {
      $ctx.find(`.${pluginClass}`)[pluginName]();
    });
  },
  destroy($context) {
    const $ctx = $context || $('html');

    mapDOMPluginElements($ctx, (pluginClass, pluginName) => {
      $ctx.find(`.${pluginClass}`)[pluginName]('destroy');
    });
  }
};

import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'modal';

class Modal {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$close = $('.c-modal__close');
    this.$el.on('click', this.openModal.bind(this));
    this.$close.on('click', this.closeModal.bind(this));
  }

  openModal(e) {
    e.preventDefault();
    const target = $(e.delegateTarget).attr('href');
    $(target).addClass('is-open');
    const hidden1 = $(e.delegateTarget).data('hidden1');
    const hidden2 = $(e.delegateTarget).data('hidden2');
    const luogo = $(e.delegateTarget).data('luogo');
    const titolo = $(e.delegateTarget).data('titolo');
    const descrizione = $(e.delegateTarget).data('descrizione');

    $(target)
      .find('#hidden1')
      .val(hidden1);

    $(target)
      .find('#hidden2')
      .val(hidden2);

    $(target)
      .find('#data')
      .text(hidden2);

    $(target)
      .find('#luogo')
      .text(luogo);

    $(target)
      .find('#titolo')
      .text(titolo);

    $(target)
      .find('#descrizione')
      .html(descrizione);
    // $('.c-header__home').toggleClass('d-none');
    return this;
  }

  closeModal(e) {
    e.preventDefault();
    // const target = $(e.delegateTarget).attr('href');
    $('.c-modal').removeClass('is-open');
    // $('.c-header__home').toggleClass('d-none');
    return this;
  }

  destroy() {
    return this;
  }
}

Modal.DEFAULTS = {};

plugin(pluginName, Modal);

import $ from 'jquery';
import plugin from './plugin';

const pluginName = 'loading';

class Loading {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$window = $(window);
    this.$window.bind('load', this.init());
  }

  init() {
    // setTimeout(function () {
    setTimeout(() => {
      $(this.$el).fadeOut(200);
      $(this.$el).addClass('d-none');
    }, 1000);

    // return this;
  }

  destroy() {
    return this;
  }
}

Loading.DEFAULTS = {};

plugin(pluginName, Loading);

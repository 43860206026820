import $ from 'jquery';
import Swiper from 'swiper/js/swiper';
import plugin from './plugin';

window.jQuery = $;

const pluginName = 'cardslider';
class Cardslider {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.cardIstance = null;
    this.$cardContainer = this.$el.find('.c-card-slider__container');
    this.$cardNuber = this.$el.find('.c-card-slider__number');
    this.$cardCurrent = this.$el.find('.c-card-slide__current');
    this.$cardTotal = this.$el.find('.c-card-slide__total');
    this.$cardNext = this.$el.find('.c-card-slider__row-right');
    this.$cardBack = this.$el.find('.c-card-slider__row-left');
    this.init();
  }

  init() {
    if (this.$el.find('.c-card-big').length > 0) {
      this.cardIstance = new Swiper(this.$cardContainer, {
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheelControl: false,
        loop: false,
        pagination: {
          el: this.$cardNuber,
          clickable: false,
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            this.$cardCurrent.text(current);
            this.$cardTotal.text(total);
          }
        },
        navigation: {
          nextEl: this.$cardNext,
          prevEl: this.$cardBack
        }
      });
    } else {
      this.cardIstance = new Swiper(this.$cardContainer, {
        spaceBetween: 30,
        loop: false,
        pagination: {
          el: this.$cardNuber,
          clickable: false,
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            this.$cardCurrent.text(current);
            this.$cardTotal.text(total);
          }
        },
        breakpoints: {
          300: {
            slidesPerView: 1
          },
          400: {
            slidesPerView: 1
          },
          576: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 2
          },
          1440: {
            slidesPerView: 2
          },
          1500: {
            slidesPerView: 2
          },
          1920: {
            slidesPerView: 3
          }
        },
        navigation: {
          nextEl: this.$cardNext,
          prevEl: this.$cardBack
        }
      });
    }

    return this;
  }

  destroy() {
    /* this.pageIstance.destroy(); */
    return this;
  }
}

Cardslider.DEFAULTS = {};

plugin(pluginName, Cardslider);

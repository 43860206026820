import $ from 'jquery';
import Swiper from 'swiper/js/swiper';
import plugin from './plugin';

window.jQuery = $;
require('@fancyapps/fancybox/dist/jquery.fancybox');

const pluginName = 'gallery';
class Gallery {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.galleryIstance = null;
    this.galleryContainer = this.$el.find('.c-gallery__wrapper');
    this.$cardNext = this.$el.find('.c-gallery__row-right');
    this.$cardBack = this.$el.find('.c-gallery__row-left');
    this.$html = $('html');
    this.$img = this.$el.find('img.lazyload');

    // this.init();
    this.$img.on('change', this.init());
  }

  init() {
    this.galleryIstance = new Swiper(this.galleryContainer, {
      spaceBetween: 15,
      loop: false,
      freeMode: true,
      breakpoints: {
        300: {
          slidesPerView: 1
        },
        400: {
          slidesPerView: 1
        },
        640: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 2
        },
        1440: {
          slidesPerView: 3
        }
      },
      navigation: {
        nextEl: this.$cardNext,
        prevEl: this.$cardBack
      }
    });

    $('[data-fancybox="gallery"]').fancybox({
      buttons: [
        // "zoom",
        // "share",
        // "slideShow",
        // "fullScreen",
        // "download",
        'thumbs',
        'close'
      ]
    });

    return this;
  }

  destroy() {
    this.galleryIstance.destroy();
    $.fancybox.destroy();
  }
}

Gallery.DEFAULTS = {};

plugin(pluginName, Gallery);

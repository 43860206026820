import $ from 'jquery';
import Swiper from 'swiper/js/swiper';
import WheelIndicator from 'wheel-indicator';
import plugin from './plugin';

window.jQuery = $;

const pluginName = 'swiperpage';
class Swiperpage {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.pageIstance = null;
    this.wheelIstance = null;
    this.$body = $('body');
    this.$window = $(window);
    this.$html = $('html');
    this.$pagination = this.$body.find('.c-shoulder__nav');
    this.$swiperslide = this.$el.find('.section-slide');
    this.$menuButton = this.$body.find('.this');
    this.$menuButton.on('click', this.onClickMenu.bind(this));
    this.init();
    this.onScroll();
  }

  init() {
    console.log(this.$window.height());
    console.log(`is desktop: ${this.$html.hasClass('desktop')}`);
    if (this.$window.height() > 820 || this.$window.width() > 420) {
      console.log(`is start`);
      this.$swiperslide.each((index, element) => {
        const title = $(element).data('name');
        const numero = $(element).data('numero');
        const background = $(element).data('image');

        if (background !== 'true') {
          $(element).addClass('c-scrollbar__background-color');
        }

        this.$pagination.append(
          `<a class="c-button-side c-button-side--hollow " data-slide="${numero}" id="select${numero}">
                <span class="c-button-side__text text-light">${title}</span>
              </a>`
        );
      });

      this.pageIstance = new Swiper(this.$el, {
        direction: 'vertical',
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 0,
        parallax: true,
        speed: 500,
        mousewheel: false,
        simulateTouch: true,
        centeredSlides: true,
        keyboard: true,
        freeMode: false,
        freeModeSticky: true,

        pagination: {
          el: this.$pagination,
          clickable: true,
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            $('.c-button-side').removeClass('is-select');
            $(`#select${current}`).addClass('is-select');

            if (current !== 1) {
              $('.c-header').addClass('on-scroll');
            } else {
              $('.c-header').removeClass('on-scroll');
            }

            const background = $(`#section${current}`).data('image');
            if (background !== true) {
              $('.c-button-side').removeClass('c-button-side--hollow');
              $('.c-button-side__text').removeClass('text-light');
              $('.c-header__button').addClass('c-header__button--dark');
              $('.c-header__icon').addClass('c-header__icon--dark');
              $('.c-shoulder').addClass('c-shoulder--dark');
            } else {
              $('.c-button-side').addClass('c-button-side--hollow');
              $('.c-button-side__text').addClass('text-light');
              $('.c-header__button').removeClass('c-header__button--dark');
              $('.c-header__icon').removeClass('c-header__icon--dark');
              $('.c-shoulder').removeClass('c-shoulder--dark');
            }
          }
        }
      });

      const instance = this.pageIstance;
      $('.c-button-side').on('click', function(e) {
        const slide = $(this).data('slide');
        instance.slideTo(slide - 1);
      });
    }
  }

  onScroll() {
    if (this.$window.height() > 820 || this.$window.width() > 420) {
      // eslint-disable-line
      const instance = this.pageIstance; // eslint-disable-line
      this.wheelIstance = new WheelIndicator({
        // eslint-disable-line
        elem: document.querySelector('.swiper-container-vertical'), // eslint-disable-line
        callback(e) {
          // eslint-disable-line
          if (e.direction === 'up') instance.slidePrev();
          // eslint-disable-line
          else instance.slideNext(); // eslint-disable-line
        } // eslint-disable-line
      }); // eslint-disable-line
    }
  } // eslint-disable-line

  onClickMenu(e) {
    e.preventDefault();
    const target = $(e.delegateTarget).data('slide');
    // console.log($(`#${target}`));
    $(`#${target}`).click();
    $('.c-header').toggleClass('is-open');
    $('.c-menu').toggleClass('is-open');
    return this;
  }

  destroy() {
    return this;
  }
}

Swiperpage.DEFAULTS = {};

plugin(pluginName, Swiperpage);

import $ from 'jquery';
// import ScrollReveal from 'scrollreveal';

import plugin from './plugin';

const pluginName = 'screveal';

class Screveal {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$window = $(window);
    this.$header = $('body').find('.c-header');
    this.reveal = null;
    this.$sectionPage = this.$el.find('.c-sholder-menu');
    // console.log(this.$header);
    // this.init();
    this.$window.on('load', this.init());
    this.$window.on('scroll', this.onScroll.bind(this));
  }

  init() {
    this.$sectionPage.each((index, Element) => {
      const $id = $(Element).attr('id');
      // console.log($id);
      const $position = $(`#${$id}`).offset().top;
      // console.log($position);
      // console.log($(`#${$id}`).position().top);
      $(`#${$id}`).attr('data-position', $position);
      this.onScroll();
    });
    window.Waypoint.refreshAll();
    return this;
  }

  onRetart() {
    this.$sectionPage.each((index, Element) => {
      const $id = $(Element).attr('id');
      // console.log($id)
      const $position = $(`#${$id}`).offset().top;
      // console.log($position);
      // console.log($(`#${$id}`).position().top);
      $(`#${$id}`).attr('data-position', $position);
    });
    return this;
  }

  onScroll() {
    // console.log($(window).scrollTop());
    window.Waypoint.refreshAll();
    const $scroll = $(window).scrollTop();
    this.onRetart();
    if ($scroll >= 700) {
      $(this.$header).addClass('on-scroll');
    } else {
      $(this.$header).removeClass('on-scroll');
    }

    this.$sectionPage.each((index, Element) => {
      const $id = $(Element).attr('id');
      const $postionGet = $(`#${$id}`).offset().top;
      if ($postionGet - 250 <= $scroll) {
        const $n = $(`#${$id}`).data('numero');
        $(`#select${$n}`).addClass('is-select');
        $(`#select${$n}`)
          .siblings()
          .removeClass('is-select');

        const background = $(`#section${$n}`).data('image');

        if (background !== true) {
          $('.c-button-side').removeClass('c-button-side--hollow');

          $('.c-button-side__text').removeClass('text-light');

          $('.c-header__button').addClass('c-header__button--dark');

          $('.c-header__icon').addClass('c-header__icon--dark');

          $('.c-shoulder').addClass('c-shoulder--dark');

          $('.c-header--dark').show();

          $('.c-header--white').hide();
        } else {
          $('.c-button-side').addClass('c-button-side--hollow');

          $('.c-button-side__text').addClass('text-light');

          $('.c-header__button').removeClass('c-header__button--dark');

          $('.c-header__icon').removeClass('c-header__icon--dark');

          $('.c-shoulder').removeClass('c-shoulder--dark');

          $('.c-header--dark').hide();

          $('.c-header--white').show();
        }
      }
    });
    return this;
  }

  destroy() {
    return this;
  }
}

Screveal.DEFAULTS = {};

plugin(pluginName, Screveal);

import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'form';

class Form {
  constructor(element, options) {
    this.$el = $(element);

    this.options = options;
    this.init();
  }

  init() {
    $('input[type=file]').change(function() {
      $(this)
        .parents()
        .find('.custom-file-label')
        .text($(this).val());
    });

    return this;
  }
}

Form.DEFAULTS = {};

plugin(pluginName, Form);

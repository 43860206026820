import OutdatedBrowserRework from 'outdated-browser-rework';
import OutdatedBrowserReworkI18n from 'outdated-browser-rework/languages.json';

// Add outdated browser banner for old browsers
export default OutdatedBrowserRework({
  browserSupport: {
    Chrome: 40,
    Edge: 39,
    Safari: 10,
    'Mobile Safari': 10,
    Firefox: 50,
    Opera: 50,
    Vivaldi: 1,
    IE: 10
  },
  requireChromeOnAndroid: true,
  isUnknownBrowserOK: false,
  language: document.documentElement.lang,
  messages: OutdatedBrowserReworkI18n
});

import LazySizes from 'lazysizes';
import LazySizesPluginRespImg from 'lazysizes/plugins/respimg/ls.respimg';
import LazySizesPluginParentFit from 'lazysizes/plugins/parent-fit/ls.parent-fit';
import LazySizesPluginObjectFit from 'lazysizes/plugins/object-fit/ls.object-fit';

export default {
  LazySizes,
  LazySizesPluginRespImg,
  LazySizesPluginParentFit,
  LazySizesPluginObjectFit
};

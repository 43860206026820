import $ from 'jquery';
import WebFont from 'webfontloader';

import Utils from './utils';
import Plugins from './plugins';
// Expose jQuery Globally for Fuckin BOW-CMS
window.jQuery = $;

// Load Webfont Async
WebFont.load({
  google: {
    families: [
      'Open Sans:300,400,700' // base
    ]
  },
  active() {
    Plugins.init();
    console.log('load');
  }
});

// Plugins.init();

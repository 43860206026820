import $ from 'jquery';
import Swiper from 'swiper/js/swiper';
import plugin from './plugin';

window.jQuery = $;

const pluginName = 'cardslidervertical';
class Cardslidervertical {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.cardIstance = null;
    this.$cardContainer = this.$el.find('.c-card-slider-verical__container');

    this.init();
  }

  init() {
    const slide = 1;
    this.cardIstance = new Swiper(this.$cardContainer, {
      direction: 'vertical',
      slidesPerView: slide,
      spaceBetween: 0,
      mousewheelControl: false,
      freeMode: true,
      speed: 800,
      navigation: {
        nextEl: '.c-card-slider-verical__row-up',
        prevEl: '.c-card-slider-verical__row-down'
      }
    });
  }

  destroy() {
    return this;
  }
}

Cardslidervertical.DEFAULTS = {};

plugin(pluginName, Cardslidervertical);

import $ from 'jquery';
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';

import plugin from './plugin';

const pluginName = 'wall';

class Wall {
  constructor(element, options) {
    jQueryBridget('masonry', Masonry, $);
    jQueryBridget('imagesLoaded', imagesLoaded, $);
    jQueryBridget('isotope', Isotope, $);
    this.$el = $(element);
    this.options = options;
    this.wallIstance = null;
    this.$wall = this.$el.find('.c-wall__block');
    this.$tag = this.$el.find('select');
    this.$img = this.$el.find('img.lazyload');

    $(window).on('load', this.init());
    this.$tag.on('change', this.filters.bind(this));
  }

  init() {
    this.wallIstance = this.$wall;

    setTimeout(() => {
      // this.wallIstance.imagesLoaded(() => {
      this.wallIstance.isotope({
        percentPosition: true,
        itemSelector: '.c-wall__item',
        columnWidth: '.grid-sizer',
        filter: '*'
      });
      // this.wallIstance.addClass('is-loaded');
      // });
    }, 500);

    console.log(this.$el.data('loadfont'));
    if ($('.js-wall').data('loadfont') === 1) {
      console.log('test');
      this.filters.bind(this);
    }
  }

  filters(e) {
    const Value = [];

    this.$el.find('option:selected').each(function(index) {
      const String = `.${$(this).attr('value')}`;
      if (String !== '.') {
        Value.push(String);
      }
    });

    const FilterRead = Value.join('');
    console.log(FilterRead);

    this.wallIstance.isotope({
      filter: FilterRead
    });
    return this;
  }

  onClickTag(e) {
    e.preventDefault();
    this.filters(e);
  }

  destroy() {
    return this;
  }
}

Wall.DEFAULTS = {};

plugin(pluginName, Wall);

export default Wall;

import FastClick from './fastclick';
import LazySizes from './lazysizes';
import DeferredStyle from './deferredStyle';
import ViewportUnitsBuggyfill from './viewportUnitsBuggyfill';
import OutdatedBrowser from './outdatedBrowser';

export default {
  FastClick,
  LazySizes,
  DeferredStyle,
  ViewportUnitsBuggyfill,
  OutdatedBrowser
};

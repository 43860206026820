import $ from 'jquery';
import plugin from './plugin';

require('waypoints/lib/noframework.waypoints.min');

const pluginName = 'reveal';

class Reveal {
  constructor(element, options) {
    this.$el = $(element);
    this.$body = $('body');
    this.$html = $('html');
    this.waypoint = null;
    this.options = options;

    this.setWaypoint();
  }

  setWaypoint() {
    this.waypoint = new window.Waypoint({
      element: this.$el.get(0),
      // context: !this.isDesktop() ? window : $('.c-container').get(0),
      offset: '95%',
      handler: direction => {
        if (!this.isDesktop()) {
          this.waypoint.destroy();

          this.$el.addClass('is-started');
        } else {
          this.$el.addClass('is-started');
        }
      }
    });
  }

  isDesktop() {
    return this.$html.hasClass('desktop');
  }

  destroy() {
    if (this.waypoint) {
      this.waypoint.destroy();
    }
  }
}

Reveal.DEFAULTS = {
  delay: 0.5
};

plugin(pluginName, Reveal);

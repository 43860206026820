import $ from 'jquery';
import Swiper from 'swiper/js/swiper';
import plugin from './plugin';

window.jQuery = $;

const pluginName = 'menuslider';
class Menuslider {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.cardIstance = null;
    this.init();
  }

  init() {
    this.cardIstance = new Swiper(this.$el, {
      spaceBetween: 10,
      slidesPerView: 2,
      centeredSlides: true,
      loop: false,
      pagination: {
        el: '.swiper-pagination'
      }
      /* breakpoints: {
        360: {
          slidesPerView: 1
        },
        576: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 4
        }
      } */
    });
    return this;
  }

  destroy() {
    /* this.pageIstance.destroy(); */
    return this;
  }
}

Menuslider.DEFAULTS = {};

plugin(pluginName, Menuslider);

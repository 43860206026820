import $ from 'jquery';
import jump from 'jump.js';

import plugin from './plugin';

const pluginName = 'jumpto';

class Jumpto {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$window = $(window);
    this.$body = $('body');
    this.$sectionPage = this.$el.find('.c-sholder-menu');
    this.$pagination = $('body').find('.c-shoulder__nav');

    this.init();

    this.$menuButton = this.$body.find('.c-menu-list__button');
    this.$sectionButton = this.$body.find('.c-button-side');
    this.$sectionCover = this.$body.find('.c-button-cover');

    this.$sectionButton.on('click', this.onClickSide.bind(this));
    this.$menuButton.on('click', this.onClickMenu.bind(this));
    this.$sectionCover.on('click', this.onClickCover.bind(this));
  }

  init() {
    console.log('test');
    this.$sectionPage.each((index, Element) => {
      const title = $(Element).data('name');
      const numero = $(Element).data('numero');
      const background = $(Element).data('image');

      if (background !== 'true') {
        $(Element).addClass('c-scrollbar__background-color');
        $('.c-button-side').removeClass('c-button-side--hollow');

        $('.c-button-side__text').removeClass('text-light');

        $('.c-header__button').addClass('c-header__button--dark');

        $('.c-header__icon').addClass('c-header__icon--dark');

        $('.c-shoulder').addClass('c-shoulder--dark');
      }
      if (index === 0) {
        this.$pagination.append(
          `<a href="#section${numero}" class="c-button-side c-button-side--hollow is-select" data-slide="${numero}" id="select${numero}">
            <span class="c-button-side__text text-light">${title}</span>
          </a>`
        );
        // jump(`#section${numero}`);
      } else {
        this.$pagination.append(
          `<a href="#section${numero}" class="c-button-side c-button-side--hollow" data-slide="${numero}" id="select${numero}">
            <span class="c-button-side__text text-light">${title}</span>
          </a>`
        );
      }
    });

    return this;
  }

  onClickSide(e) {
    e.preventDefault();
    const target = $(e.delegateTarget).attr('href');
    jump(target, {
      offset: -100
    });

    return this;
  }

  onClickMenu(e) {
    e.preventDefault();
    const gettarget = $(e.delegateTarget).attr('href');
    if ($(e.delegateTarget).data('inpage') === true) {
      const target = gettarget.substring(gettarget.indexOf('#') + 1);
      jump(`#${target}`, {
        offset: -100
      });
      $('.c-header').toggleClass('is-open');
      $('.c-menu').toggleClass('is-open');
    } else {
      const url = gettarget.substr(0, gettarget.indexOf('#'));
      const target = gettarget.substring(gettarget.indexOf('#') + 1);
      console.log(url);

      window.location.href = gettarget;
      jump(`#${target}`, {
        offset: -100
      });
    }
    return this;
  }

  onClickCover(e) {
    e.preventDefault();
    const gettarget = $(e.delegateTarget).attr('href');
    if ($(e.delegateTarget).data('inpage') === true) {
      const target = gettarget.substring(gettarget.indexOf('#') + 1);
      jump(`#${target}`, {
        offset: -100
      });
    } else {
      window.location.href = gettarget;
    }
    return this;
  }

  destroy() {
    return this;
  }
}

Jumpto.DEFAULTS = {};

plugin(pluginName, Jumpto);
